import React, { useEffect, useState } from "react";
import WebFont from "webfontloader";
import ReactGa4 from "react-ga4";
import { Box } from "@mui/system";

// Web app components
import SearchBox from "./Header/SearchBox";
import SearchResults from "./Main/SearchResults";

// Web app includes
import { searchCommands } from "./includes/searchCommands";
import { commands } from "./includes/commands";
import { stripHTML } from "./includes/stripHTML";

import "./styles/App.scss";

function App() {
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState();
  const [suggestions, setSuggestions] = useState(true);
  ReactGa4.initialize("G-S7YBV73S86");

  useEffect(() => {
    ReactGa4.send({ hitType: "pageview" });
  }, []);

  // Primary search method for cheat sheet
  const handleSearch = (str) => {
    setResults({});

    // Clean up search to remove HTML & transform to lowercase
    const searchPhrase = stripHTML(str).toLowerCase();

    // Set State for Keyword and Suggestions
    setKeyword(searchPhrase);
    setSuggestions(true);

    // Split search phrase into array
    let words = searchPhrase
      .replace(new RegExp("([^\\w\\s])", "g"), "")
      .split(" ");
    words = words.filter((word) => word.trim() !== "");

    // Only start looking after user has entered three characters
    if (str.length >= 3) {
      // Iterate thru commands & set results for table (or suggestions)
      setResults(searchCommands({ commands: commands, keywords: words }));

      // Otherwise just show the search box
    } else {
      setResults();
      setSuggestions(false);
    }
  };

  // Load web app fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Raleway", "Roboto+Slab", "Open+Sans", "Roboto"],
      },
    });
  }, []);

  // Render the component
  return (
    <Box className="search">
      <SearchBox
        keyword={keyword}
        results={results}
        show={suggestions}
        setShow={setSuggestions}
        search={handleSearch}
      />
      {results && results?.length >= 1 && suggestions === false && (
        <SearchResults results={results} show={suggestions} keyword={keyword} />
      )}
    </Box>
  );
}

export default App;
