import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import Characters from "./Characters";
import { stripHTML } from "../includes/stripHTML";

const SearchRow = ({ num, val }) => {
  return (
    <Box
      className={"search__card " + val.s}
      style={{
        animation: "moveIn " + (0.2 * (num + 1)) / 10 + "s ease-out ",
      }}
    >
      <Box className="card__command">
        <Typography>
          <Characters str={val.c} />
        </Typography>
      </Box>
      <Box className="card__description">{stripHTML(val.d)}</Box>
    </Box>
  );
};
export default SearchRow;
