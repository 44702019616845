import React, { useEffect } from "react";
import { Box } from "@mui/system";
import SearchRow from "./SearchRow";
import ReactGa4 from "react-ga4";

import "./styles/Main.scss";

const SearchResults = ({ results, show, keyword }) => {
  useEffect(() => {
    if (results && results?.length >= 1 && show === false) {
      console.log("New GA hit");
      document.title = '"' + keyword + '" | Vim Cheat Sheet';
      ReactGa4.send({
        hitType: "pageview",
        page:
          "https://vim.siteschema.com/#/search/" +
          keyword.replace(new RegExp("([\\s+])", "g"), "-"),
      });
    }
  }, [keyword]);

  return (
    <React.Fragment>
      {results && show === false && (
        <Box className="search__results">
          <Box className="search__card head">
            <Box className="card__command">Command</Box>
            <Box className="card__description">Description</Box>
          </Box>
          <Box className="search__results__table">
            {results.map((val, key) => {
              return <SearchRow key={val.d + "-" + key} num={key} val={val} />;
            })}
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};
export default SearchResults;
