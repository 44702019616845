import logo from "./images/site-schema-logo.png";

const Title = () => {
  return (
    <a id="logo" href="https://www.siteschema.com/">
      <img
        className="logo"
        src={logo}
        alt="Site Schema - Hosting & Development "
      />
    </a>
  );
};

export default Title;
