import { useEffect, useRef, useState } from "react";
import { Close, Search } from "@mui/icons-material";
import { Chip, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";

import Suggestions from "./Suggestions";
import Title from "./Title";

import "./styles/Header.scss";

const SearchBox = ({ results, search, keyword, show, setShow }) => {
  const wrapperRef = useRef(null);
  const [suggestions, setSuggestions] = useState(show);

  const handleShow = (state) => {
    setShow(state);
    setSuggestions(state);
  };

  const Chips = ({ kw }) => {
    return (
      <Chip
        label={kw}
        icon={<Search />}
        size="small"
        onClick={(e) => {
          search(kw);
          handleShow(false);
        }}
      />
    );
  };

  // Detect focus loss of search box
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleShow(false);
        return false;
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <Box className="search__container">
      <Title />

      <Box className="search__bar__container">
        <Box
          ref={wrapperRef}
          className={
            "search__bar " +
            (results && results?.length >= 1 && suggestions
              ? "search--expand"
              : "")
          }
        >
          <Box className="search__form">
            <Search className="search__inputButton" />

            <input
              autoFocus={true}
              className="search__inputSearch"
              value={keyword}
              onFocus={(e) => handleShow(true)}
              onChange={(e) => {
                handleShow(true);
                search(e.target.value);
              }}
              placeholder="What are you trying to do in Vim?"
              onKeyDown={(e) => e.key === "ArrowDown" && handleShow(true)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleShow(false);
                  e.target.blur();
                }
              }}
            />
            {results?.length > 0 && (
              <Tooltip title="Clear search" arrow placement="bottom">
                <Close
                  className="search__inputClear"
                  onClick={(e) => search("")}
                />
              </Tooltip>
            )}
          </Box>
          {results?.length >= 1 && suggestions && (
            <Suggestions
              results={results}
              search={search}
              keyword={keyword}
              handleShow={handleShow}
            />
          )}
        </Box>
        <Box className="search__examples">
          <Typography
            variant="caption"
            style={{ fontWeight: "bold", color: "#666" }}
          >
            SUGGESTIONS:
          </Typography>
          {["indent", "append", "replace", "save", "macro"].map((val, key) => {
            return <Chips key={key} kw={val} />;
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default SearchBox;
