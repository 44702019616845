export const highlightMatches = ({ commands, keywords }) => {
  commands.forEach((match, key) => {
    keywords.forEach((word) => {
      if (word.trim() !== "") {
        let o = { ...match };
        o.d = commands[key].d.replace(new RegExp(word, "g"), `<b>${word}</b>`);

        commands[key] = o;
      }
    });
  });
  return commands;
};
