const move1 = " (words can contain punctuation)";
// https://vim.rtorr.com/

export const commands = [
  // GLOBAL
  { s: "global", c: ":|h[elp] keyword", d: "open help for keyword" },
  { s: "global", c: ":|sav[eas] file", d: "save file as" },
  { s: "global", c: ":|clo[se]", d: "close current pane" },
  { s: "global", c: ":|ter[minal]", d: "open a terminal window" },
  { s: "global", c: "K", d: "open man page for word under the cursor" },

  // CURSOR MOVEMENT
  { s: "move", c: "h", d: "move cursor left" },
  { s: "move", c: "j", d: "move cursor down" },
  { s: "move", c: "k", d: "move cursor up" },
  { s: "move", c: "l", d: "move cursor right" },
  { s: "move", c: "g|j", d: "move cursor down (multi-line text)" },
  { s: "move", c: "g|k", d: "move cursor up (multi-line text)" },
  { s: "move", c: "H", d: "move to top of screen" },
  { s: "move", c: "M", d: "move to middle of screen" },
  { s: "move", c: "L", d: "move to bottom of screen" },
  { s: "move", c: "w", d: "jump forwards to the start of a word" },
  { s: "move", c: "W", d: "jump forwards to the start of a word" + move1 },
  { s: "move", c: "b", d: "jump backwards to the start of a word" },
  { s: "move", c: "B", d: "jump backwards to the start of a word" + move1 },
  { s: "move", c: "e", d: "jump forwards to the end of a word" },
  { s: "move", c: "E", d: "jump forwards to the end of a word" + move1 },
  { s: "move", c: "g|e", d: "jump backwards to the end of a word" },
  { s: "move", c: "g|E", d: "jump backwards to the end of a word" + move1 },
  {
    s: "move",
    c: "%",
    d: "move to matching character (default supported pairs: '()', '{}', '[]' - use :h matchpairs in vim for more info)",
  },
  { s: "move", c: "0", d: "jump to the start of the line" },
  { s: "move", c: "$", d: "jump to the end of the line" },
  { s: "move", c: "^", d: "jump to the first non-blank character of the line" },
  {
    s: "move",
    c: "g|_",
    d: "jump to the last non-blank character of the line",
  },
  { s: "move", c: "g|g", d: "go to first line of the document" },
  { s: "move", c: "G", d: "go to last line of the document" },
  { s: "move", c: "5|g|g", d: "go to line five" },
  { s: "move", c: "5|G", d: "go to line five" },
  { s: "move", c: "g|d", d: "move to local declaration" },
  { s: "move", c: "g|D", d: "move to global declaration" },
  { s: "move", c: "f|x", d: "jump to next occurrence of character x" },
  { s: "move", c: "t|x", d: "jump to before next occurrence of character x" },
  { s: "move", c: "F|x", d: "jump to previous occurrence of character x" },
  {
    s: "move",
    c: "T|x",
    d: "jump to before previous occurrence of character x",
  },
  { s: "move", c: ";", d: "repeat previous f, t, F or T movement" },
  { s: "move", c: ",", d: "repeat previous f, t, F or T movement, backward" },
  {
    s: "move",
    c: "}",
    d: "jump to next paragraph (or function/block, when editing code)",
  },
  {
    s: "move",
    c: "{",
    d: "jump to previous paragraph (or function/block, when editing code)",
  },
  { s: "move", c: "z|z", d: "center cursor on screen" },
  {
    s: "move",
    c: "Ctrl + e",
    d: "move screen down one line (without moving cursor)",
  },
  {
    s: "move",
    c: "Ctrl + y",
    d: "move screen up one line (without moving cursor)",
  },
  { s: "move", c: "Ctrl + b", d: "move back one full screen" },
  { s: "move", c: "Ctrl + f", d: "move forward one full screen" },
  { s: "move", c: "Ctrl + d", d: "move forward 1/2 screen" },
  { s: "move", c: "Ctrl + u", d: "move back 1/2 screen" },

  // INSERT MODE - INSERTING/APPENDING TEXT
  { s: "insert", c: "i", d: "insert before the cursor" },
  { s: "insert", c: "I", d: "insert at the beginning of the line" },
  { s: "insert", c: "a", d: "insert (append) after the cursor" },
  { s: "insert", c: "A", d: "insert (append) at the end of the line" },
  { s: "insert", c: "o", d: "append (open) a new line below the current line" },
  { s: "insert", c: "o", d: "append (open) a new line above the current line" },
  { s: "insert", c: "e|a", d: "insert (append) a the end of the word" },
  {
    s: "insert",
    c: "Ctrl + h",
    d: "delete the character before the cursor during insert mode",
  },
  {
    s: "insert",
    c: "Ctrl + w",
    d: "delete word before the cursor during insert mode",
  },
  { s: "insert", c: "Ctrl + j", d: "begin new line during insert mode" },
  {
    s: "insert",
    c: "Ctrl + t",
    d: "indent (move right) line one shiftwidth during insert mode",
  },
  {
    s: "insert",
    c: "Ctrl + d",
    d: "de-indent (move left) line one shiftwidth during insert mode",
  },
  {
    s: "insert",
    c: "Ctrl + n",
    d: "insert (auto-complete) next match before the cursor during insert mode",
  },
  {
    s: "insert",
    c: "Ctrl + p",
    d: "insert (auto-complete) previous match before the cursor during insert mode",
  },
  { s: "insert", c: "Ctrl + r|x", d: "insert the contents of register x" },
  {
    s: "insert",
    c: "Ctrl + o|x",
    d: "Temporarily enter normal mode to issue one normal-mode command x",
  },
  { s: "insert", c: "Esc", d: "exit insert mode" },

  // EDITING
  { s: "editing", c: "r", d: "replace a single character" },
  {
    s: "editing",
    c: "R",
    d: "replace more than one character, until ESC is pressed",
  },
  {
    s: "editing",
    c: "J",
    d: "join line below to the current one with one space in between",
  },
  {
    s: "editing",
    c: "g|J",
    d: "join line below to the current one without space in between",
  },
  { s: "editing", c: "g|w|i|p", d: "reflow paragraph" },
  { s: "editing", c: "g|~", d: "switch case up to motion" },
  { s: "editing", c: "g|u", d: "change to lowercase up to motion" },
  { s: "editing", c: "g|U", d: "change to uppercase up to motion" },
  { s: "editing", c: "c|c", d: "change (replace) entire line" },
  { s: "editing", c: "c|$", d: "change (replace) to the end of the line" },
  { s: "editing", c: "C", d: "change (replace) to the end of the line" },
  { s: "editing", c: "c|i|w", d: "change (replace) entire word" },
  { s: "editing", c: "c|w", d: "change (replace) to the end of the word" },
  { s: "editing", c: "c|e", d: "change (replace) to the end of the word" },
  { s: "editing", c: "s", d: "delete character and substitute text" },
  { s: "editing", c: "S", d: "delete line and substitute text (same as cc)" },
  { s: "editing", c: "x|p", d: "transpose two letters (delete and paste)" },
  { s: "editing", c: "u", d: "undo" },
  { s: "editing", c: "U", d: "restore (undo) last changed line" },
  { s: "editing", c: "Ctrl + r", d: "redo" },
  { s: "editing", c: ".", d: "repeat last comm" },

  // MARKING TEXT ( VIDUAL MODE)
  {
    s: "mark",
    c: "v",
    d: "start visual mode, mark lines, then do a command (like y-yank)",
  },
  { s: "mark", c: "V", d: "start linewise visual mode" },
  { s: "mark", c: "o", d: "move to other end of marked area" },
  { s: "mark", c: "Ctrl + v", d: "start visual block mode" },
  { s: "mark", c: "O", d: "move to other corner of block" },
  { s: "mark", c: "a|w", d: "mark a word" },
  { s: "mark", c: "a|b", d: "a block with ()" },
  { s: "mark", c: "a|B", d: "a block with {}" },
  { s: "mark", c: "a|t", d: "a block with <> tags" },
  { s: "mark", c: "i|b", d: "inner block with ()" },
  { s: "mark", c: "i|B", d: "inner block with {}" },
  { s: "mark", c: "i|t", d: "inner block with <> tags" },
  { s: "mark", c: "Esc", d: "exit visual mode" },

  // VISUAL COMMANDS
  { s: "viz", c: ">", d: "shift text right" },
  { s: "viz", c: "<", d: "shift text left" },
  { s: "viz", c: "y", d: "yank (copy) marked text" },
  { s: "viz", c: "d", d: "delete marked text" },
  { s: "viz", c: "~", d: "switch case" },
  { s: "viz", c: "u", d: "change marked text to lowercase" },
  { s: "viz", c: "U", d: "change marked text to uppercase" },

  // REGISTERS
  { s: "reg", c: ":|reg[isters]", d: "show registers content" },
  { s: "reg", c: "x|y", d: "yank into register x" },
  { s: "reg", c: "x|p", d: "paste contents of register x" },
  { s: "reg", c: "+|y", d: "yank into the system clipboard register" },
  { s: "reg", c: "+|p", d: "paste from the system clipboard register" },

  // MARKS & POSITIONS
  { s: "mark", c: ":|marks", d: "list of marks" },
  { s: "mark", c: "m|a", d: "set current position for mark A" },
  { s: "mark", c: "`|a", d: "jump to position of mark A" },
  { s: "mark", c: "y|`|a", d: "yank text to position of mark A" },
  {
    s: "mark",
    c: "`|0",
    d: "go to the position where Vim was previously exited",
  },
  { s: "mark", c: '`|"', d: "go to the position when last editing this file" },
  {
    s: "mark",
    c: "`|.",
    d: "go to the position of the last change in this file",
  },
  { s: "mark", c: "`|`", d: "go to the position before the last jump" },
  { s: "mark", c: ":|ju[mps]", d: "list of jumps" },
  { s: "mark", c: "Ctrl + i", d: "go to newer position in jump list" },
  { s: "mark", c: "Ctrl + o", d: "go to older position in jump list" },
  { s: "mark", c: ":|changes", d: "list of changes" },
  { s: "mark", c: "g|,", d: "go to newer position in change list" },
  { s: "mark", c: "g|;", d: "go to older position in change list" },
  { s: "mark", c: "Ctrl + j", d: "jump to the tag under cursor" },

  // MACROS
  { s: "macro", c: "q|a", d: "record macro a" },
  { s: "macro", c: "q", d: "stop recording macro" },
  { s: "macro", c: "@|a", d: "run macro a" },
  { s: "macro", c: "@|@", d: "rerun last run macro" },

  // CUT & PASTE
  { s: "paste", c: "y", d: "yank (copy) a line" },
  { s: "paste", c: "2|y|y", d: "yank (copy) 2 lines" },
  {
    s: "paste",
    c: "y|w",
    d: "yank (copy) the characters of the word from the cursor position to the start of the next word",
  },
  { s: "paste", c: "y|i|w", d: " yank (copy) word under the cursor" },
  {
    s: "paste",
    c: "y|a|w",
    d: " yank (copy) word under the cursor and the space after or before it",
  },
  { s: "paste", c: "y|$", d: "yank (copy) to end of line" },
  { s: "paste", c: "Y", d: "yank (copy) to end of line" },
  { s: "paste", c: "p", d: "paste text after the cursor" },
  { s: "paste", c: "P", d: "paste text before the cursor" },

  {
    s: "paste",
    c: "g|p",
    d: "put (paste) the clipboard after cursor and leave cursor after the new text",
  },
  {
    s: "paste",
    c: "g|P",
    d: "put (paste) before cursor and leave cursor after the new text",
  },
  { s: "paste", c: "d|d", d: "delete (cut) a line" },
  { s: "paste", c: "2|d|d", d: "delete (cut) 2 lines" },
  {
    s: "paste",
    c: "d|w",
    d: "delete (cut) the characters of the word from the cursor position to the start of the next word",
  },
  { s: "paste", c: "d|i|w", d: "delete (cut) word under the cursor" },
  {
    s: "paste",
    c: "d|a|w",
    d: "delete (cut) word under the cursor and the space after or before it",
  },
  { s: "paste", c: "d|$", d: "delete (cut) to the end of the line" },
  { s: "paste", c: "D", d: "delete (cut) to the end of the line" },
  { s: "paste", c: "x", d: "delete (cut) character" },

  // INDENT TEXT
  { s: "indent", c: ">|>", d: "indent (move right) line one shiftwidth" },
  { s: "indent", c: "<|<", d: "de-indent (move left) line one shiftwidth" },
  {
    s: "indent",
    c: ">|%",
    d: "indent a block with () or {} (cursor on brace)",
  },
  { s: "indent", c: ">|i|b", d: "indent inner block with ()" },
  { s: "indent", c: ">|a|t", d: "indent a block with <> tags" },
  { s: "indent", c: "3|=|=", d: "re-indent 3 lines" },
  {
    s: "indent",
    c: "=|%",
    d: "re-indent a block with () or {} (cursor on brace)",
  },
  { s: "indent", c: "=|i|B", d: "re-indent inner block with {}" },
  { s: "indent", c: "g|g|=|G", d: "re-indent entire buffer" },
  { s: "indent", c: "]|p", d: "paste and adjust indent to current line" },

  // EXITING
  { s: "exit", c: ":|w", d: "write (save) the file, but don't exit" },
  {
    s: "exit",
    c: ":|w !sudo tee %",
    d: "write out (save) the current file using sudo",
  },
  { s: "exit", c: ":|wq", d: "write (save) and quit" },
  { s: "exit", c: ":|x", d: "write (save) and quit" },
  { s: "exit", c: "Z|Z", d: "write (save) and quit" },
  { s: "exit", c: ":|q", d: "quit (fails if there are unsaved changes)" },
  { s: "exit", c: ":|q!", d: "quit and throw away unsaved changes" },
  { s: "exit", c: "Z|Q", d: "quit and throw away unsaved changes" },
  { s: "exit", c: ":|wqa", d: "write (save) and quit on all tabs" },

  // SEARCH & REPLACE
  { s: "search", c: "/|pattern", d: "search for pattern" },
  { s: "search", c: "?|pattern", d: "search backward for pattern" },
  {
    s: "search",
    c: "\\|v|pattern",
    d: "'very magic' pattern: non-alphanumeric characters are interpreted as special regex symbols (no escaping needed)",
  },
  { s: "search", c: "n", d: "repeat search in same direction" },
  { s: "search", c: "N", d: "repeat search in opposite direction" },
  {
    s: "search",
    c: ":|%s/old/new/g",
    d: "replace all old with new throughout file",
  },
  {
    s: "search",
    c: ":|%s/old/new/gc",
    d: "replace all old with new throughout file with confirmations",
  },
  {
    s: "search",
    c: ":|noh[lsearch]",
    d: "remove highlighting of search matches",
  },

  // SEARCH IN MULTIPLE FILES
  {
    s: "ms",
    c: ":|vim[grep] /pattern/ {`{file}`}",
    d: "search for pattern in multiple files",
  },
  { s: "ms", c: ":|cn[ext]", d: "jump to the next match" },
  { s: "ms", c: ":|cp[revious]", d: "jump to the previous match" },
  {
    s: "ms",
    c: ":|cope[n]",
    d: "open a window containing the list of matches",
  },
  { s: "ms", c: ":|ccl[ose]", d: "close the quickfix window" },

  // TABS
  { s: "tabs", c: ":|tabnew", d: "open a file in a new tab" },
  { s: "tabs", c: ":|tabnew {page.words.file}", d: "open a file in a new tab" },
  {
    s: "tabs",
    c: "Ctrl + w|T",
    d: "move the current split window into its own tab",
  },
  { s: "tabs", c: "g|t", d: "move to the next tab" },
  { s: "tabs", c: ":|tabn[ext]", d: "move to the next tab" },
  { s: "tabs", c: "g|T", d: "move to the previous tab" },
  { s: "tabs", c: ":|tabp[revious]", d: "move to the previous tab" },
  { s: "tabs", c: "#|g|t", d: "move to tab number #" },
  {
    s: "tabs",
    c: ":|tabm[ove]",
    d: "move current tab to the #th position (indexed from 0)",
  },
  {
    s: "tabs",
    c: ":|tabc[lose]",
    d: "close the current tab and all its windows",
  },
  {
    s: "tabs",
    c: ":|tabo[nly]",
    d: "close all tabs except for the current one",
  },
  {
    s: "tabs",
    c: ":|tabdo|command",
    d: "run the command on all tabs (e.g. :tabdo q - closes all opened tabs)",
  },

  // WORKING WITH MULTIPLE FILES
  { s: "multi", c: ":|e[dit] file", d: "edit a file in a new buffer" },
  { s: "multi", c: ":|bn[ext]", d: "go to the next buffer" },
  { s: "multi", c: ":|bp[revious]", d: "go to the previous buffer" },
  { s: "multi", c: ":|bd[elete]", d: "delete a buffer (close a file)" },
  { s: "multi", c: ":|b[uffer] #", d: "go to a buffer by index #" },
  { s: "multi", c: ":|b[uffer] file", d: "go to a buffer by file" },
  { s: "multi", c: "l|s", d: "list all open buffers" },
  { s: "multi", c: ":|buffers", d: "list all open buffers" },
  {
    s: "multi",
    c: ":|sp[lit] file",
    d: "open a file in a new buffer and split window",
  },
  {
    s: "multi",
    c: ":|vs[plit] file",
    d: "open a file in a new buffer and vertically split window",
  },
  {
    s: "multi",
    c: ":|vert[ical] ba[ll]",
    d: "edit all buffers as vertical windows",
  },
  { s: "multi", c: ":|tab ba[ll]", d: "edit all buffers as tabs" },
  { s: "multi", c: "Ctrl + w|s", d: "split window" },
  { s: "multi", c: "Ctrl + w|v", d: "split window vertically" },
  { s: "multi", c: "Ctrl + w|w", d: "switch windows" },
  { s: "multi", c: "Ctrl + w|q", d: "quit a window" },
  { s: "multi", c: "Ctrl + w|x", d: "exchange current window with next one" },
  { s: "multi", c: "Ctrl + w|=", d: "make all windows equal height & width" },
  {
    s: "multi",
    c: "Ctrl + w|h",
    d: "move cursor to the left window (vertical split)",
  },
  {
    s: "multi",
    c: "Ctrl + w|l",
    d: "move cursor to the right window (vertical split)",
  },
  {
    s: "multi",
    c: "Ctrl + w|j",
    d: "move cursor to the window below (horizontal split)",
  },
  {
    s: "multi",
    c: "Ctrl + w|k",
    d: "move cursor to the window above (horizontal split)",
  },
  {
    s: "multi",
    c: "Ctrl + w|H",
    d: "make current window full height at far left (leftmost vertical window)",
  },
  {
    s: "multi",
    c: "Ctrl + w|L",
    d: "make current window full height at far right (rightmost vertical window)",
  },
  {
    s: "multi",
    c: "Ctrl + w|J",
    d: "make current window full width at the very bottom (bottommost horizontal window)",
  },
  {
    s: "multi",
    c: "Ctrl + w|K",
    d: "make current window full width at the very top (topmost horizontal window)",
  },

  // DIFF
  { s: "diff", c: "z|f", d: "manually define a fold up to motion" },
  { s: "diff", c: "z|d", d: "delete fold under the cursor" },
  { s: "diff", c: "z|a", d: "toggle fold under the cursor" },
  { s: "diff", c: "z|o", d: "open fold under the cursor" },
  { s: "diff", c: "z|c", d: "close fold under the cursor" },
  { s: "diff", c: "z|r", d: "reduce (open) all folds by one level" },
  { s: "diff", c: "z|m", d: "fold more (close) all folds by one level" },
  { s: "diff", c: "z|i", d: "toggle folding functionality" },
  { s: "diff", c: "]|c", d: "jump to start of next change" },
  { s: "diff", c: "[|c", d: "jump to start of previous change" },
  { s: "diff", c: "d|o", d: "obtain (get) difference (from other buffer)" },
  {
    s: "diff",
    c: ":|diffg[et]",
    d: "obtain (get) difference (from other buffer)",
  },
  { s: "diff", c: "d|p", d: "put difference (to other buffer)" },
  { s: "diff", c: ":|diffp[ut]", d: "put difference (to other buffer)" },
  { s: "diff", c: ":|diffthis", d: "make current window part of diff" },
  { s: "diff", c: ":|dif[fupdate]", d: "update differences" },
  {
    s: "diff",
    c: ":|diffo[off]",
    d: "switch off diff mode for current window",
  },
];
