import { Search } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const Suggestions = ({ results, search, handleShow, keyword }) => {
  return (
    <Box className="search__suggestions">
      <hr />
      {results.map((val, key) => {
        return (
          <Box
            key={"suggestions-" + key}
            className="search__suggestion"
            onClick={(e) => {
              search(val.d);
              handleShow(false);
            }}
          >
            <Search
              className="search__inputButton"
              onClick={(e) => {
                search(keyword);
              }}
            />
            <Typography
              dangerouslySetInnerHTML={{ __html: val.d }}
            ></Typography>
          </Box>
        );
      })}
    </Box>
  );
};
export default Suggestions;
