import { highlightMatches } from "./highlightMatches";

export const searchCommands = ({ keywords, commands }) => {
  let matches = [];

  // Iterate through all commands
  commands.forEach((o) => {
    // Then through all search words
    const match = keywords.every((word) => {
      if (o.d.includes(word) === false) {
        return false;
      }
      return true;
    });
    // If all words are found, add to matches array
    if (match) {
      matches.push(o);
    }
  });

  // Format matches into new array where search words are bold
  let styledMatches = highlightMatches({
    commands: matches,
    keywords: keywords,
  });

  return styledMatches;
};
